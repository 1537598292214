import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.scss";

interface Slide {
  src: string;
  title?: string;
  color?: string; 
}

interface SwiperProps {
  slides: Slide[];
  classNames: string;
  slidesPerView?: number;
  shadow?: boolean;
  extraClass?: string;
}

const HeroSwiper: React.FC<SwiperProps> = ({
  slides,
  classNames,
  slidesPerView = 1,
  shadow = true,
}) => {

  return (
    <>
      <SwiperComponent
        spaceBetween={10}
        className={`${classNames}`}
        pagination={{clickable: true}}
        modules={[Navigation, Autoplay, Pagination]}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        loop={true}
        
        initialSlide={0}
        centeredSlides={true}
        slidesPerView={1.15}
        effect={"fade"}
        autoplay={{ delay: 5000 }}
        breakpoints={{
          800: {
            slidesPerView: slidesPerView,
            spaceBetween: 50,
          },
        }}

      >
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
        {slides.map((slide, index) => (
          <>
            <SwiperSlide key={index}>
              {shadow && <div className="gradient"></div>}
              <div
                className="swiper-img-back"
                style={{ backgroundImage: `url(${slide.src})` }}
              >
                <div className="swiper-title">
                  <p style={{color: `${slide.color}`}}>{slide.title}</p>

                </div>
              </div>
            </SwiperSlide>
          </>
        ))}
      </SwiperComponent>
    </>
  );
};

export default HeroSwiper;
