export const galleryPics = [
    {
        src : '../images/gallery/1.png', 
        title: 'Entrance Arrival',
        color: '#2c321d'

    },
    {
        src : '../images/gallery/2.png',
        title: 'Contemporary Warehouse Workspace'
    },
    {
        src : '../images/gallery/3.png',
        title: 'Pocket Park'
    },
    {
        src : '../images/gallery/4.png',
        title : 'Ground Floor Courtyard'
    },
    {
        src : '../images/gallery/5.png',
        title : 'Indicative CAT B',
        color: '#2c321d'
    },
    {
        src : '../images/gallery/6.jpeg',
        title: 'Indicative CAT B',
        color: '#2c321d'
    },
    {
        src : '../images/gallery/7.png',
        title: 'Roof Terrace'
    },
    {
        src : '../images/gallery/8.jpg',
        title: 'Ground Floor Reception',
    },
]