import React, { useEffect, useState } from 'react';
import Gallery from "../../components/swiper/swiper";
import { galleryPics } from "../../utils/home";
import { motion } from "framer-motion";
import "../../scss/global.scss";
import "swiper/css/effect-fade";
import "./home.scss";
import "swiper/css";

// TypeScript declaration for Vimeo Player
declare global {
  interface Window {
    Vimeo: any;
  }
}

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('active')) {
      setIsModalOpen(true);
    }

    window.scrollTo(0, 0);
  }, []);

  const handlePlayVideoClick = () => {
    setIsModalOpen(true);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('active', 'true');
    window.history.pushState({}, '', newUrl);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete('active');
    window.history.pushState({}, '', newUrl);
  };

  useEffect(() => {
    if (isModalOpen) {
      // Load Vimeo Player API script if not already loaded
      if (!window.Vimeo) {
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.onload = () => {
          const iframe = document.querySelector('iframe');
          if (iframe) {
            const player = new window.Vimeo.Player(iframe);
            player.play().catch((error: any) => {
              console.error('Error trying to play the video:', error);
            });
          }
        };
        document.body.appendChild(script);
      } else {
        // If Vimeo script is already loaded
        const iframe = document.querySelector('iframe');
        if (iframe) {
          const player = new window.Vimeo.Player(iframe);
          player.play().catch((error: any) => {
            console.error('Error trying to play the video:', error);
          });
        }
      }
    }
  }, [isModalOpen]);

  return (
    <>
      <div className={`modal ${isModalOpen ? 'active' : ''}`}>
        <div className="modal-content">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/982458782?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              title="One Derry Animation"
            />
          </div>
          {/* <button className="close-modal" onClick={handleCloseModal}>Close</button> */}
          <img onClick={handleCloseModal} className="close-modal" src="../images/closemodal.svg" alt="" />
        </div>
      </div>

      <div className="hero">
        <div className="text-animation">
          <motion.img
            className="hero-img"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 0.75 }}
            src="../images/hero.png"
            alt=""
          />
        </div>
      </div>
      <div className="contain-wrapper">
        <div className="contain">
          <div className="copy">
            <h1>
              Cut from a <br /> different cloth
            </h1>
            <p>
              A unique thread in the fabric of Kensington's history, One Derry
              St sits on the site where talented workers, seamstresses, tailors
              lived and worked, filling the floors of Derry & Tom’s department
              store with the clothes that spilled out onto the fashionable
              streets of Kensington.
            </p>
            <p
              style={{
                paddingTop: "1.5rem",
                letterSpacing: "1px",
                fontSize: "24px",
              }}
            >
              AVAILABLE NOW
            </p>
          </div>
          <Gallery slides={galleryPics} classNames={" "} />
          <div className="copy-two">
            <p>
              Now a contemporary warehouse workspace of 30,000 sq ft, the
              building offers a unique, headquarters opportunity for the next
              generation of makers, doers and thinkers.
              <br />
              <br />
              With an events-ready roof terrace in the style of our famous
              neighbour, providing a private venue to relax, entertain and bask
              in lush, green surroundings.
            </p>
          </div>
          <div className="playvideo" onClick={handlePlayVideoClick}>
            <img src="../images/WatchTheFilm.svg" alt="" />
            <h2>WATCH THE FILM</h2>
          </div>
          <div className="highlights">
            <div className="highlight">
              <div
                className="img-holder"
                style={{
                  backgroundImage: "url(../images/highlights/1.jpg)",
                }}
              />
              <div className="highlight-copy">
                <h3>
                  Sustainable <br /> measures
                </h3>
                <p>
                  Sustainability and the environment have been considered at
                  every stage of the design.
                </p>
              </div>
            </div>
            <div className="highlight">
              <div
                className="img-holder"
                style={{
                  backgroundImage: "url(../images/highlights/2.jpg)",
                }}
              />
              <div className="highlight-copy">
                <h3>
                  dressed <br /> to impress
                </h3>
                <p>
                  The former warehouse has been refurbished to provide modern
                  workspace whilst retaining original character.
                </p>
              </div>
            </div>
            <div className="highlight">
              <div
                className="img-holder"
                style={{
                  backgroundImage: "url(../images/highlights/3.jpg)",
                }}
              />
              <div className="highlight-copy">
                <h3>
                  always <br /> in fashion
                </h3>
                <p>
                  One Derry St sits in the heart of London’s fashionable
                  Kensington and is surrounded by high-end shops and
                  restaurants.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border"></div>
      </div>
    </>
  );
};

export default Home;
